<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <vs-popup classContent="popup-example" title="Nouveau pays" :active.sync="popupPays">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Code pays" v-validate="'required'" name="code_pays" v-model="code_pays" />
              <span class="text-danger text-sm"  v-show="errors.has('code_pays')">{{ errors.first('code_pays') }}</span>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Pays" v-validate="'required'" name="pays" v-model="pays" />
              <span class="text-danger text-sm"  v-show="errors.has('pays')">{{ errors.first('pays') }}</span>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Monnaie" v-validate="'required'" name="monnaie" v-model="monnaie" />
              <span class="text-danger text-sm"  v-show="errors.has('monnaie')">{{ errors.first('monnaie') }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="creatPays" :disabled="!validateForm">Enregistrer</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="pays = ''; ">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <vs-popup classContent="popup-example" title="Modifier une pays" :active.sync="popupPaysUpdate">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Code pays" v-validate="'required'" name="code_pays" v-model="code_pays" />
              <span class="text-danger text-sm"  v-show="errors.has('code_pays')">{{ errors.first('code_pays') }}</span>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Pays" v-validate="'required'" name="pays" v-model="pays" />
              <span class="text-danger text-sm"  v-show="errors.has('pays')">{{ errors.first('pays') }}</span>
            </div>
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Monnaie" v-validate="'required'" name="monnaie" v-model="monnaie" />
              <span class="text-danger text-sm"  v-show="errors.has('monnaie')">{{ errors.first('monnaie') }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="updatePays" :disabled="!validateForm">Modifier</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="pays = '';  popupPaysUpdate = false;">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="AlignJustifyIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Liste de pays</span>
            </div>
            <vs-divider />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" pagination max-items="10" search :data="paysData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="popupPays=true">Nouveau pays</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="index">
                  ##
                </vs-th>
                <vs-th sort-key="code_pays">
                  Code
                </vs-th>
                <vs-th sort-key="pays">
                  Pays
                </vs-th>
                <vs-th sort-key="monnaie">
                  Devise
                </vs-th>
                <vs-th>
                  Action
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].code_pays">
                    {{data[indextr].code_pays}}
                  </vs-td>

                  <vs-td :data="data[indextr].pays">
                    {{data[indextr].pays}}
                  </vs-td>

                  <vs-td :data="data[indextr].monnaie">
                    {{data[indextr].monnaie}}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updatePaysShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupPays: false,
      popupPaysUpdate: false,
      // status: false,
      pays: '',
      code_pays: '',
      monnaie: '',
      selected:[],
      payss:[],
      paysId: '',
      paysD: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.pays !== '' && this.code_pays !== '' && this.monnaie !== ''
    },
    paysData () {
      return this.$store.state.pays.pays
    }
  },

  methods: {
    creatPays () {
      const payload = {
        pays: this.pays,
        code_pays: this.code_pays,
        monnaie: this.monnaie
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/createPays', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.$vs.loading.close()
          this.popupPays = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    confirmDeleteRecord (data) {
      this.paysD = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le pays "${data.pays}"`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/dropPays', this.paysD)
        .then(() => {
          this.showDeleteSuccess()
          this.$vs.loading.close()
          this.paysD = ''
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Supprimer',
        text: 'Le pays selectionné a bien été supprimée'
      })
    },
    getPays () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/getPays')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
        })

    },
    updatePaysShowForm (data) {

      this.pays = data.pays
      this.code_pays = data.code_pays
      this.monnaie = data.monnaie
      this.paysId = data._id
      this.popupPaysUpdate = true
    },
    updatePays () {
      const payload = {
        pays: this.pays,
        code_pays: this.code_pays,
        monnaie: this.monnaie,
        id: this.paysId
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/updatePays', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.$vs.loading.close()
          this.popupPaysUpdate = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    resete_data () {
      this.pays = this.monnaie = this.code_pays = ''
    }
  },
  created () {
    this.getPays()
  }
}
</script>

